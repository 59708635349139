import { createAction } from '@reduxjs/toolkit';
import {
  GetPracticesListRequestPayload,
  GetPracticesListSuccessPayload,
  SetSearchPracticesListFilters,
  GetPracticeDetailsRequestPayload,
  GetPracticeDetailsSuccessPayload,
  GetPracticesCategoriesSuccessPayload,
  GetPracticesStatusesSuccessPayload,
  GetPracticeMedicalServicesRequestPayload,
  GetPraticeMedicalServicesSuccessPayload,
  GetPraticeMedicalServicesDentalSuccessPayload,
  MedicalServiceEntity,
  GetPracticeDoctorsRequestPayload,
  GetPraticeDoctorsSuccessPayload,
  PutPracticeRequestPayload,
  PutPracticeSuccessPayload,
  PutProntoCarePracticeRequestPayload,
  DeletePracticeServicesRequestPayload,
  DeletePracticeServicesSuccessPayload,
  PayPracticeCouponRequestPayload,
  PayPracticeCouponSuccessPayload,
  ClosePracticeRequestPayload,
  ClosePracticeSuccessPayload,
  AddInvoiceRequestPayload,
  AddInvoiceSuccessPayload,
  AddInvoiceErrorsPayload,
  GetFamilyListRequestPayload,
  GetFamilyListSuccessPayload,
  GetVenuesListRequestPayload,
  GetVenuesListSuccessPayload,
  CreateCouponPracticeRequestPayload,
} from './types';

export const getPracticesRequest = createAction<GetPracticesListRequestPayload>('ACTION/GET_PRACTICES_LIST_REQUEST');
export const getPracticesSuccess = createAction<GetPracticesListSuccessPayload>('ACTION/GET_PRACTICES_LIST_SUCCESS');
export const getPracticesFailed = createAction('ACTION/GET_PRACTICES_LIST_FAILED');

export const getPracticesAppointmentsSuccess = createAction<GetPracticesListSuccessPayload>(
  'ACTION/GET_PRACTICES_APPOINTMENTS_LIST_SUCCESS',
);
export const getPracticesAppointmentsFailed = createAction('ACTION/GET_PRACTICES_APPOINTMENTS_LIST_FAILED');

export const getPracticesToManageSuccess = createAction<GetPracticesListSuccessPayload>(
  'ACTION/GET_PRACTICES_TO_MANAGE_LIST_SUCCESS',
);
export const getPracticesToManageFailed = createAction('ACTION/GET_PRACTICES_TO_MANAGE_LIST_FAILED');

export const setSearchPracticesListFilters = createAction<SetSearchPracticesListFilters>(
  'ACTION/SET_SEARCH_PRACTICES_LIST_FILTERS',
);
export const resetSearchPracticesListFilters = createAction('ACTION/RESET_SEARCH_PRACTICES_LIST_FILTERS');
export const setPracticesListInitialPage = createAction('ACTION/SET_PRACTICES_INITIAL_PAGE');

export const getPracticeDetailsRequest = createAction<GetPracticeDetailsRequestPayload>(
  'ACTION/GET_PRACTICE_DETAILS_LIST_REQUEST',
);
export const getPracticeDetailsSuccess = createAction<GetPracticeDetailsSuccessPayload>(
  'ACTION/GET_PRACTICE_DETAILS_LIST_SUCCESS',
);
export const getPracticeDetailsFailed = createAction('ACTION/GET_PRACTICE_DETAILS_FAILED');
export const resetPracticeDetails = createAction('ACTION/RESET_PRACTICE_DETAILS');

export const getPracticesCategoriesRequest = createAction('ACTION/GET_PRACTICES_CATEGORIES_REQUEST');
export const getPracticesCategoriesSuccess = createAction<GetPracticesCategoriesSuccessPayload>(
  'ACTION/GET_PRACTICES_CATEGORIES_SUCCESS',
);
export const getPracticesCategoriesFailed = createAction('ACTION/GET_PRACTICES_CATEGORIES_FAILED');

export const getPracticesStatusesRequest = createAction('ACTION/GET_PRACTICES_STATUSES_REQUEST');
export const getPracticesStatusesSuccess = createAction<GetPracticesStatusesSuccessPayload>(
  'ACTION/GET_PRACTICES_STATUSES_SUCCESS',
);
export const getPracticesStatusesFailed = createAction('ACTION/GET_PRACTICES_STATUSES_FAILED');

export const getPracticeMedicalServicesRequest = createAction<GetPracticeMedicalServicesRequestPayload>(
  'ACTION/GET_PRACTICE_MEDICAL_SERVICES_REQUEST',
);
export const getPracticeMedicalServicesSuccess = createAction<GetPraticeMedicalServicesSuccessPayload>(
  'ACTION/GET_PRACTICE_MEDICAL_SERVICES_SUCCESS',
);
export const getPracticeMedicalServicesFailed = createAction('ACTION/GET_PRACTICE_MEDICAL_SERVICES_FAILED');

export const getPracticeMedicalServicesDentalRequest = createAction(
  'ACTION/GET_PRACTICE_MEDICAL_SERVICES_DENTAL_REQUEST',
);
export const getPracticeMedicalServicesDentalSuccess = createAction<GetPraticeMedicalServicesDentalSuccessPayload>(
  'ACTION/GET_PRACTICE_MEDICAL_SERVICES_DENTAL_SUCCESS',
);
export const getPracticeMedicalServicesDentalFailed = createAction(
  'ACTION/GET_PRACTICE_MEDICAL_SERVICES_DENTAL_FAILED',
);

export const getPracticeDoctorsRequest = createAction<GetPracticeDoctorsRequestPayload>(
  'ACTION/GET_PRACTICE_DOCTORS_REQUEST',
);
export const getPracticeDoctorsSuccess = createAction<GetPraticeDoctorsSuccessPayload>(
  'ACTION/GET_PRACTICE_DOCTORS_SUCCESS',
);
export const getPracticeDoctorsFailed = createAction('ACTION/GET_PRACTICE_DOCTORS_FAILED');

export const setMedicalServiceRequest = createAction<MedicalServiceEntity[]>('ACTION/SET_MEDICAL_SERVICE_REQUEST');
export const resetMedicalServiceRequest = createAction('ACTION/RESET_MEDICAL_SERVICE_REQUEST');
export const resetMedicalServiceDentalRequest = createAction('ACTION/RESET_MEDICAL_SERVICE_DENTAL_REQUEST');
export const resetAvailableMedicalServiceRequest = createAction('ACTION/RESET_AVAILABLE_MEDICAL_SERVICE_REQUEST');
export const resetPracticeDoctorsRequest = createAction('ACTION/RESET_PRACTICE_DOCTORS_REQUEST');

export const putPracticesRequest = createAction<PutPracticeRequestPayload>('ACTION/PUT_PRACTICE_REQUEST');
export const putPracticesSuccess = createAction<PutPracticeSuccessPayload>('ACTION/PUT_PRACTICE_SUCCESS');
export const putPracticesFailed = createAction('ACTION/PUT_PRACTICE_FAILED');
export const resetPutPracticeModalStatus = createAction('ACTION/RESET_PUT_PRACTICE_MODAL_STATUS');

export const putProntoCarePracticesRequest = createAction<PutProntoCarePracticeRequestPayload>(
  'ACTION/PUT_PRONTOCARE_PRACTICE_REQUEST',
);
export const putProntoCarePracticesSuccess = createAction<PutPracticeSuccessPayload>(
  'ACTION/PUT_PRONTOCARE_PRACTICE_SUCCESS',
);
export const putProntoCarePracticesFailed = createAction('ACTION/PUT_PRONTOCARE_PRACTICE_FAILED');

export const deletePracticeServicesRequest = createAction<DeletePracticeServicesRequestPayload>(
  'ACTION/DELETE_PRACTICE_SERVICES_REQUEST',
);
export const deletePracticeServicesSuccess = createAction<DeletePracticeServicesSuccessPayload>(
  'ACTION/DELETE_PRACTICE_SERVICES_SUCCESS',
);
export const deletePracticeServicesFailed = createAction('ACTION/DELETE_PRACTICE_SERVICES_FAILED');

export const payPracticesCouponRequest = createAction<PayPracticeCouponRequestPayload>(
  'ACTION/PAY_PRACTICE_COUPON_REQUEST',
);
export const payPracticesCouponSuccess = createAction<PayPracticeCouponSuccessPayload>(
  'ACTION/PAY_PRACTICE_COUPON_SUCCESS',
);
export const payPracticesCouponFailed = createAction('ACTION/PAY_PRACTICE_COUPON_FAILED');

export const closePracticeRequest = createAction<ClosePracticeRequestPayload>('ACTION/CLOSE_PRACTICE_REQUEST');
export const closePracticeSuccess = createAction<ClosePracticeSuccessPayload>('ACTION/CLOSE_PRACTICE_SUCCESS');
export const closePracticeFailed = createAction('ACTION/CLOSE_PRACTICE_FAILED');

export const addInvoiceRequest = createAction<AddInvoiceRequestPayload>('ACTION/ADD_INVOICE_REQUEST');
export const addInvoiceSuccess = createAction<AddInvoiceSuccessPayload>('ACTION/ADD_INVOICE_SUCCESS');
export const addInvoiceErrors = createAction<AddInvoiceErrorsPayload>('ACTION/ADD_INVOICE_ERRORS');
export const addInvoiceFailed = createAction('ACTION/ADD_INVOICE_FAILED');

export const resetUploadedInvoicesIdsRequest = createAction('ACTION/RESET_UPLOADED_INVOICES_IDS');
export const resetUploadedInvoicesErrorsRequest = createAction('ACTION/RESET_UPLOADED_INVOICES_ERRORS');

export const getFamilyListRequest = createAction<GetFamilyListRequestPayload>('ACTION/GET_FAMILY_LIST_REQUEST');
export const getFamilyListSuccess = createAction<GetFamilyListSuccessPayload>('ACTION/GET_FAMILY_LIST_SUCCESS');
export const getFamilyListFailed = createAction('ACTION/GET_FAMILY_LIST_FAILED');
export const resetFamilyListRequest = createAction('ACTION/RESET_FAMILY_LIST');

export const getVenuesListRequest = createAction<GetVenuesListRequestPayload>('ACTION/GET_VENUES_LIST_REQUEST');
export const getVenuesListSuccess = createAction<GetVenuesListSuccessPayload>('ACTION/GET_VENUES_LIST_SUCCESS');
export const getVenuesListFailed = createAction('ACTION/GET_VENUES_LIST_FAILED');
export const resetVenuesListRequest = createAction('ACTION/RESET_VENUES_LIST');

export const createPracticeCouponRequest = createAction<CreateCouponPracticeRequestPayload>(
  'ACTION/CREATE_PRACTICE_COUPON_REQUEST',
);
export const createPracticeCouponSuccess = createAction('ACTION/CREATE_PRACTICE_COUPON_SUCCESS');
export const createPracticeCouponFailed = createAction('ACTION/CREATE_PRACTICE_COUPON_FAILED');
export const resetCreatePracticeCoupon = createAction('ACTION/RESET_CREATE_PRACTICE_COUPON');
