import { USER_ROLE } from '@apps/goodme/app/containers/Auth/constants.js';
import { CacheChecker } from '@apps/pu/components/CacheChecker';
import { AppRoutes } from '@apps/pu/routes/routesList';
import staticImages from '@apps/pu/theme/images';
import customTheme, { palette } from '@apps/pu/theme/theme';
import {
  Flex,
  Box,
  GridItem,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner,
  Checkbox,
  Image,
  Text,
  Link,
  InputGroup,
  InputRightElement,
  useToast,
  ListItem,
  UnorderedList,
  Divider,
} from '@chakra-ui/react';
import { useAuth } from '@common/auth/hooks/useAuth';
import { getPUAnalyticsUUID, isTooSmall, isMatchingRules } from '@utils';
import { History, LocationState } from 'history';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import packageJson from '../../../../package.json';
import RequestUsername from './RequestUsername';
import ResetPassword from './ResetPassword';

// https://choc-ui.tech/  <--- for some prebuild components

interface LoginPageProps {
  history: History<LocationState>;
}
const LoginPage: FC<LoginPageProps> = (props) => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    login,
    isChangePasswordRequired,
    changePasswordToken,
    changePassword,
    changePwdSuccessfull,
    checkMaintenance,
    activeMaintenance,
  } = useAuth();
  const [loginLoading, setLoginLoading] = useState(false);
  const [changePswLoading, setChangePswLoading] = useState(false);
  // const [wrongUsername, setWrongUsername] = useState(false);
  const [data, setData] = useState({
    username: '',
    password: '',
    rememberMe: true,
  });
  const [changePwdDataFlow, setChangePwdDataFlow] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [changePswFormErrors, setChangePswFormErrors] = useState<string[]>([]);
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const nonce = uuidv4();
  const role = USER_ROLE;
  const username = data?.username;
  const password = data?.password;
  const rememberMe = data?.rememberMe;
  const buttonDisabled = !!username && !!password;
  const oldPassword = changePwdDataFlow?.oldPassword;
  const newPassword = changePwdDataFlow?.newPassword;
  const confirmNewPassword = changePwdDataFlow?.confirmNewPassword;
  const resetButtonDisabled = !!oldPassword && !!newPassword && !!confirmNewPassword;

  const onLoginFormChange = useCallback(
    (key, val) => {
      setData({
        ...data,
        [key]: val,
      });
      /* if (key === 'username') {
        setWrongUsername(/\s/.test(val));
      } */
    },
    [data],
  );

  const onTogglePasswordShowHide = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const onHandleChangePassword = useCallback(
    (key, val) => {
      setChangePwdDataFlow({
        ...changePwdDataFlow,
        [key]: val,
      });
    },
    [changePwdDataFlow],
  );

  const onToggleChangePasswordShowHide = useCallback(
    (field) => setShowChangePassword({ ...showChangePassword, [field]: !showChangePassword[field] }),
    [showChangePassword],
  );

  const onNewPasswordBlur = useCallback(
    (val) => {
      const errorsObj = isMatchingRules(val, 'lowerCase|upperCase|symbols', true);
      const errors = Object.keys(errorsObj).filter((key) => !errorsObj[key]);
      if (isTooSmall(val)) errors.push('tooSmall');
      if (val !== changePwdDataFlow?.confirmNewPassword) errors.push('notMatching');
      setChangePswFormErrors(errors);
    },
    [changePwdDataFlow, setChangePswFormErrors],
  );

  const onConfirmNewPasswordBlur = useCallback(
    (val) => {
      const errors = changePswFormErrors.filter((err) => err !== 'notMatching');
      if (val !== changePwdDataFlow?.newPassword) errors.push('notMatching');
      setChangePswFormErrors(errors);
    },
    [changePwdDataFlow, changePswFormErrors, setChangePswFormErrors],
  );

  const onLogin = useCallback(async () => {
    setLoginLoading(true);
    try {
      if (!!username && !!password) {
        await login(username, password, role, nonce, rememberMe);
      }
    } catch (error) {
      toast({
        description: typeof error === 'string' ? error : (t('web.pu.common.GenericLoginError') as string),
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000,
      });
    }
    setLoginLoading(false);
  }, [login, nonce, password, rememberMe, role, t, toast, username]);

  const onResetPassword = useCallback(async () => {
    setChangePswLoading(true);
    try {
      await changePassword(username, oldPassword, newPassword, changePasswordToken || '', role);
      setData({
        username: '',
        password: '',
        rememberMe: true,
      });
      setChangePwdDataFlow({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });

      if (changePwdSuccessfull) {
        toast({
          description: t('web.pu.common.ChangePasswordSuccess') as string,
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 10000,
        });
      }
    } catch (error) {
      toast({
        description: error as string,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000,
      });
    }
    setChangePswLoading(false);
  }, [changePassword, username, oldPassword, newPassword, changePasswordToken, role, changePwdSuccessfull, toast, t]);

  useEffect(() => {
    getPUAnalyticsUUID();
    checkMaintenance();
  }, [checkMaintenance]);

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/pu/home');
    }
  }, [isAuthenticated, props.history]);

  if (activeMaintenance) {
    return <Redirect to={AppRoutes.Maintenance} />;
  }

  return (
    <CacheChecker>
      <Flex height="100%" width="100%">
        <Flex bg={palette.GREY4} p={0} w="100%" justifyContent="center" alignItems="center">
          <Box
            width={{ xs: '100%', sm: '90%', xxl: '60%' }}
            maxWidth="1000px"
            p="65px"
            bg="white"
            borderRadius="20px"
            shadow="base"
            position="relative"
          >
            <Flex
              position="absolute"
              bottom="2"
              left="0"
              width="100%"
              pl="5"
              pr="5"
              color="red"
              fontSize="12px"
              fontWeight="bold"
              justifyContent="center"
            >
              {t('web.pu.footer.message')}
            </Flex>
            {!isChangePasswordRequired ? (
              <SimpleGrid columns={2} spacing={10}>
                <Box
                  position="relative"
                  _after={{
                    content: '""',
                    position: 'absolute',
                    borderLeft: '1px solid',
                    borderLeftColor: 'grey3',
                    right: '-10px',
                    height: '100%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  paddingRight={{ xs: '0', sm: '25px' }}
                >
                  <Image width="250px" objectFit="contain" src={staticImages.AONLogo} alt="AON Logo" />

                  <Text textStyle="h6" fontSize="xs" color="grey2">
                    v.{packageJson.version}
                  </Text>

                  <Box mt="20px">
                    <Text textStyle="h2">{t('web.pu.Login.Welcome.Title')}</Text>
                  </Box>

                  <Box mt="10px">
                    <Text textStyle="body" fontSize="md">
                      {t('web.pu.Login.Welcome.InsertCredentials')}
                    </Text>
                  </Box>

                  <Box mt="10px">
                    <Text textStyle="body" fontSize="md">
                      {t('web.pu.Login.Welcome.FirstTime')}
                    </Text>
                  </Box>

                  <Flex position="absolute" bottom="30" width="100%" pr="25px" justifyContent="space-between">
                    <Link textStyle="link" fontSize="xs" href="#" id="ot-sdk-btn" className="ot-sdk-show-settings">
                      {t('web.pu.footer.cookiesSettings')}
                    </Link>
                    <Link
                      isExternal
                      textStyle="link"
                      fontSize="xs"
                      href="https://www.privacy.aon.it/Home/DisclaimerItem/0dfd764d-30a2-484d-b57a-94d4681201ee?m=1&v=1"
                    >
                      {t('web.pu.footer.cookiesPolicy')}
                    </Link>
                  </Flex>

                  <Flex position="absolute" bottom="0" width="100%" pr="25px" justifyContent="space-between">
                    <Link
                      isExternal
                      textStyle="link"
                      fontSize="xs"
                      href="https://www.privacy.aon.it/Home/DisclaimerItem/048084d0-8b9c-459c-8cbb-9752d59144a8?m=1&v=1"
                    >
                      {t('web.pu.footer.privacyLink')}
                    </Link>
                    <Link
                      isExternal
                      textStyle="link"
                      fontSize="xs"
                      href="https://www.privacy.aon.it/Home/DisclaimerItem/f0aa646f-96f6-4f40-aae7-806e68176afe?m=1&v=1"
                    >
                      {t('web.pu.footer.tcLink')}
                    </Link>
                  </Flex>

                  {/* <Box mt="10px">
                  <Text textStyle="body" fontSize="md">
                    {t('web.pu.Login.Welcome.NoMail')}
                    <Link isExternal textStyle="link">
                      {t('web.pu.Login.Welcome.ClickHere')}
                    </Link>
                  </Text>
                </Box> */}
                </Box>
                <Box as="form">
                  <Text textStyle="h6">{t('web.pu.Login.Form.Title')}</Text>
                  <SimpleGrid columns={12} mt="20px">
                    <FormControl as={GridItem} colSpan={[12, 12]} /*  isInvalid={wrongUsername} */>
                      <FormLabel htmlFor="username" textStyle="cardDetailsInputLabel">
                        {t('web.pu.Login.Form.Username')}
                        <Text as="span" textStyle="asterisk">
                          *
                        </Text>
                      </FormLabel>
                      <Input
                        onChange={(e) => onLoginFormChange('username', e.target.value)}
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        focusBorderColor="gray.300"
                        w="full"
                        fontSize="md"
                        value={data?.username}
                      />
                      <FormErrorMessage>{t('web.pu.Login.Form.UsernameNotValid')}</FormErrorMessage>
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[12, 12]} mt="20px">
                      <FormLabel htmlFor="password" textStyle="cardDetailsInputLabel">
                        {t('web.pu.Login.Form.Password')}
                        <Text as="span" textStyle="asterisk">
                          *
                        </Text>
                      </FormLabel>
                      <InputGroup justifyContent="center">
                        <Input
                          onChange={(e) => onLoginFormChange('password', e.target.value)}
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          id="password"
                          autoComplete="password"
                          focusBorderColor="gray.300"
                          w="full"
                          fontSize="md"
                          value={data?.password}
                        />
                        <InputRightElement width="50px" height="100%">
                          <Button
                            variant="ghost"
                            size="xl"
                            onClick={onTogglePasswordShowHide}
                            _hover={{ backgroundColor: 'transparent' }}
                            _active={{ backgroundColor: 'transparent' }}
                          >
                            {showPassword ? (
                              <AiOutlineEyeInvisible size={24} fill={customTheme.colors.black} />
                            ) : (
                              <AiOutlineEye size={24} fill={customTheme.colors.black} />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[12, 12]} mt="20px">
                      <Checkbox
                        size="lg"
                        isChecked={rememberMe}
                        onChange={(e) => onLoginFormChange('rememberMe', e.target.checked)}
                      >
                        <Text textStyle="body" fontSize="sm">
                          {t('web.pu.Login.Form.RememberMe')}
                        </Text>
                      </Checkbox>
                    </FormControl>
                  </SimpleGrid>

                  <Box mt="20px">
                    <Button
                      onClick={onLogin}
                      isDisabled={!buttonDisabled || loginLoading /*  || wrongUsername */}
                      variant="primary"
                      size="primaryOutlineSmall"
                      minWidth="140px"
                    >
                      {loginLoading ? <Spinner color="white" /> : t('web.pu.Login.Form.LoginButton')}
                    </Button>
                  </Box>

                  <Box mt="25px">
                    <ResetPassword />

                    <Divider my="5px" borderBottomWidth={0} />

                    <RequestUsername />
                  </Box>
                </Box>
              </SimpleGrid>
            ) : (
              <SimpleGrid columns={2} spacing={10}>
                <Box>
                  <Image width="250px" objectFit="contain" src={staticImages.AONLogo} alt="AON Logo" />

                  <Box mt="40px">
                    <Text textStyle="body" fontSize="sm">
                      {t('web.pu.Login.ChangePassword.PswRules.Intro')}
                    </Text>
                    <UnorderedList>
                      <ListItem fontSize="sm" color={changePswFormErrors.includes('tooSmall') ? 'error' : 'inherit'}>
                        {t('web.pu.Login.ChangePassword.PswRules.MinLength')}
                      </ListItem>
                      <ListItem fontSize="sm" color={changePswFormErrors.includes('lowerCase') ? 'error' : 'inherit'}>
                        {t('web.pu.Login.ChangePassword.PswRules.LowerCase')}
                      </ListItem>
                      <ListItem fontSize="sm" color={changePswFormErrors.includes('upperCase') ? 'error' : 'inherit'}>
                        {t('web.pu.Login.ChangePassword.PswRules.UpperCase')}
                      </ListItem>
                      <ListItem fontSize="sm" color={changePswFormErrors.includes('symbols') ? 'error' : 'inherit'}>
                        {t('web.pu.Login.ChangePassword.PswRules.Symbols')}
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box>
                  <Text textStyle="h5" fontSize="md">
                    {t('web.pu.Login.ChangePassword.Form.Title')}
                  </Text>
                  <SimpleGrid columns={12} mt="20px">
                    <FormControl as={GridItem} colSpan={[12, 12]}>
                      <FormLabel htmlFor="oldPassword" textStyle="cardDetailsInputLabel">
                        {t('web.pu.Login.ChangePassword.Form.CurrentPassword')}
                        <Text as="span" textStyle="asterisk">
                          *
                        </Text>
                      </FormLabel>
                      <InputGroup size="md" justifyContent="center">
                        <Input
                          onChange={(e) => onHandleChangePassword('oldPassword', e.target.value)}
                          type={showChangePassword?.oldPassword ? 'text' : 'password'}
                          name="oldPassword"
                          id="oldPassword"
                          focusBorderColor="gray.300"
                          w="full"
                          fontSize="md"
                          value={changePwdDataFlow?.oldPassword}
                        />
                        <InputRightElement width="50px" height="100%">
                          <Button
                            variant="ghost"
                            size="xl"
                            onClick={() => onToggleChangePasswordShowHide('oldPassword')}
                            _hover={{ backgroundColor: 'transparent' }}
                            _active={{ backgroundColor: 'transparent' }}
                          >
                            {showChangePassword?.oldPassword ? (
                              <AiOutlineEyeInvisible size={24} fill={customTheme.colors.black} />
                            ) : (
                              <AiOutlineEye size={24} fill={customTheme.colors.black} />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[12, 12]} mt="20px">
                      <FormLabel htmlFor="newPassword" textStyle="cardDetailsInputLabel">
                        {t('web.pu.Login.ChangePassword.Form.NewPassword')}
                        <Text as="span" textStyle="asterisk">
                          *
                        </Text>
                      </FormLabel>
                      <InputGroup size="md" justifyContent="center">
                        <Input
                          onChange={(e) => onHandleChangePassword('newPassword', e.target.value)}
                          onBlur={(e) => onNewPasswordBlur(e.target.value)}
                          type={showChangePassword?.newPassword ? 'text' : 'password'}
                          name="newPassword"
                          id="newPassword"
                          focusBorderColor="gray.300"
                          w="full"
                          fontSize="md"
                          value={changePwdDataFlow?.newPassword}
                        />

                        <InputRightElement width="50px" height="100%">
                          <Button
                            variant="ghost"
                            size="xl"
                            onClick={() => onToggleChangePasswordShowHide('newPassword')}
                            _hover={{ backgroundColor: 'transparent' }}
                            _active={{ backgroundColor: 'transparent' }}
                          >
                            {showChangePassword?.newPassword ? (
                              <AiOutlineEyeInvisible size={24} fill={customTheme.colors.black} />
                            ) : (
                              <AiOutlineEye size={24} fill={customTheme.colors.black} />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <FormControl as={GridItem} colSpan={[12, 12]} mt="20px">
                      <FormLabel htmlFor="confirmNewPassword" textStyle="cardDetailsInputLabel">
                        {t('web.pu.Login.ChangePassword.Form.ConfirmNewPassword')}
                        <Text as="span" textStyle="asterisk">
                          *
                        </Text>
                      </FormLabel>
                      <InputGroup size="md" justifyContent="center">
                        <Input
                          onChange={(e) => onHandleChangePassword('confirmNewPassword', e.target.value)}
                          onBlur={(e) => onConfirmNewPasswordBlur(e.target.value)}
                          type={showChangePassword?.confirmNewPassword ? 'text' : 'password'}
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          focusBorderColor="gray.300"
                          w="full"
                          fontSize="md"
                          value={changePwdDataFlow?.confirmNewPassword}
                        />
                        <InputRightElement width="50px" height="100%">
                          <Button
                            variant="ghost"
                            size="xl"
                            onClick={() => onToggleChangePasswordShowHide('confirmNewPassword')}
                            _hover={{ backgroundColor: 'transparent' }}
                            _active={{ backgroundColor: 'transparent' }}
                          >
                            {showChangePassword?.confirmNewPassword ? (
                              <AiOutlineEyeInvisible size={24} fill={customTheme.colors.black} />
                            ) : (
                              <AiOutlineEye size={24} fill={customTheme.colors.black} />
                            )}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {changePswFormErrors.includes('notMatching') && (
                        <Text fontSize="xs" color="error" mt="5px">
                          {t('web.pu.Login.ChangePassword.Form.Error.ConfirmMismatch')}
                        </Text>
                      )}
                    </FormControl>
                  </SimpleGrid>

                  <Box mt="20px">
                    <Button
                      onClick={onResetPassword}
                      disabled={!resetButtonDisabled || changePswFormErrors.length > 0 || changePswLoading}
                      variant="primaryOutline"
                      size="primaryOutlineSmall"
                      minWidth="140px"
                    >
                      {changePswLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        t('web.pu.Login.ChangePassword.Form.ResetButton')
                      )}
                    </Button>
                  </Box>
                </Box>
              </SimpleGrid>
            )}
          </Box>
        </Flex>
      </Flex>
    </CacheChecker>
  );
};

export default LoginPage;
