import { chakra, Link, Flex } from '@chakra-ui/react';
import { useAuth } from '@common/auth/hooks/useAuth';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// import PULanguageSwitcher from '../PULanguageSwitcher';

const PUFooter: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return null;
  }
  return (
    <chakra.footer bg="grey7" w="full" px="32px" shadow="s" position="fixed" zIndex="102" bottom="0px" minHeight="48px">
      <Flex height="100%" justifyContent="flex-end" alignItems="center">
        {/* <PULanguageSwitcher /> */}
        <Flex
          position="absolute"
          bottom="0"
          left="10"
          width="50%"
          pr="25px"
          color="red"
          fontSize="12px"
          fontWeight="bold"
          justifyContent="flex-start"
        >
          {t('web.pu.footer.message')}
        </Flex>
        <Link
          mt="15px"
          mr="25px"
          textStyle="link"
          fontSize="xs"
          href="#"
          id="ot-sdk-btn"
          className="ot-sdk-show-settings"
        >
          {t('web.pu.footer.cookiesSettings')}
        </Link>
        <Link
          mt="15px"
          pr="25px"
          isExternal
          textStyle="link"
          fontSize="xs"
          href="https://www.privacy.aon.it/Home/DisclaimerItem/048084d0-8b9c-459c-8cbb-9752d59144a8?m=1&v=1"
        >
          {t('web.pu.footer.privacyLink')}
        </Link>
        <Link
          mt="15px"
          pr="25px"
          isExternal
          textStyle="link"
          fontSize="xs"
          href="https://www.privacy.aon.it/Home/DisclaimerItem/f0aa646f-96f6-4f40-aae7-806e68176afe?m=1&v=1"
        >
          {t('web.pu.footer.tcLink')}
        </Link>
        <Link
          mt="15px"
          pr="25px"
          isExternal
          textStyle="link"
          fontSize="xs"
          href="https://www.privacy.aon.it/Home/DisclaimerItem/0dfd764d-30a2-484d-b57a-94d4681201ee?m=1&v=1"
        >
          {t('web.pu.footer.cookiesPolicy')}
        </Link>
      </Flex>
    </chakra.footer>
  );
};

export default PUFooter;
