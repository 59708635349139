import { createAction } from '@reduxjs/toolkit';
import {
  AddNewVenueRequestPayload,
  GetAllVenuesRequestPayload,
  GetAllVenuesSuccessPayload,
  GetInvoiceAddressesSingleVenueSuccessPayload,
  GetVenueDetailsRequestPayload,
  GetVenueDetailsSuccessPayload,
  GetApprovedVenueDetailsRequestPayload,
  GetApprovedVenueDetailsSuccessPayload,
  SetSearchVenuesFilters,
  GetVenueDetailsInvoiceAddressesSuccessPayload,
  PutVenueRequestPayload,
  PutVenueSuccessPayload,
  PutVenueFailedPayload,
  ShowReturnToVenueModalPayload,
  GetVenueAffiliationsRequestPayload,
  GetVenueAffiliationsSuccessPayload,
  GetVenueTypesRequestPayload,
  GetVenueTypesSuccessPayload,
  GetVenueAdditionalServicesRequestPayload,
  GetVenueAdditionalServicesSuccessPayload,
  GetVenueCurrentPracticeStatusSuccessPayload,
  GetAllApprovedVenuesRequestPayload,
  GetAllApprovedVenuesSuccessPayload,
} from './types';

export const getAllVenuesRequest = createAction<GetAllVenuesRequestPayload>('ACTION/GET_ALL_VENUES_REQUEST');
export const getAllVenuesSuccess = createAction<GetAllVenuesSuccessPayload>('ACTION/GET_ALL_VENUES_SUCCESS');
export const getAllVenuesFailed = createAction('ACTION/GET_ALL_VENUES_FAILED');
export const resetVenuesList = createAction('ACTION/RESET_VENUES_LIST');

export const getAllApprovedVenuesRequest = createAction<GetAllApprovedVenuesRequestPayload>(
  'ACTION/GET_ALL_APPROVED_VENUES_REQUEST',
);
export const getAllApprovedVenuesSuccess = createAction<GetAllApprovedVenuesSuccessPayload>(
  'ACTION/GET_ALL_APPROVED_VENUES_SUCCESS',
);
export const getAllApprovedVenuesFailed = createAction('ACTION/GET_ALL_APPROVED_VENUES_FAILED');

export const setSearchVenuesFilters = createAction<SetSearchVenuesFilters>('ACTION/SET_SEARCH_VENUES_FILTERS');
export const resetSearchVenuesFilters = createAction('ACTION/RESET_SEARCH_VENUES_FILTERS');

export const getVenueDetailsRequest = createAction<GetVenueDetailsRequestPayload>('ACTION/GET_VENUE_DETAILS_REQUEST');
export const getVenueDetailsSuccess = createAction<GetVenueDetailsSuccessPayload>('ACTION/GET_VENUE_DETAILS_SUCCESS');
export const getVenueDetailsFailed = createAction('ACTION/GET_VENUE_DETAILS_FAILED');
export const resetVenueDetailsFilters = createAction('ACTION/RESET_VENUE_DETAILS');

export const getApprovedVenueDetailsRequest = createAction<GetApprovedVenueDetailsRequestPayload>(
  'ACTION/GET_APPROVED_VENUE_DETAILS_REQUEST',
);
export const getApprovedVenueDetailsSuccess = createAction<GetApprovedVenueDetailsSuccessPayload>(
  'ACTION/GET_APPROVED_VENUE_DETAILS_SUCCESS',
);
export const getApprovedVenueDetailsFailed = createAction('ACTION/GET_APPROVED_VENUE_DETAILS_FAILED');

export const getInvoiceAddressesSingleVenueRequest = createAction('ACTION/GET_INVOICE_ADDRESSES_VENUE_REQUEST');
export const getInvoiceAddressesSingleVenueSuccess = createAction<GetInvoiceAddressesSingleVenueSuccessPayload>(
  'ACTION/GET_INVOICE_ADDRESSES_VENUE_SUCCESS',
);
export const getInvoiceAddressesSingleVenueFailed = createAction('ACTION/GET_INVOICE_ADDRESSES_VENUE_FAILED');
export const resetInvoiceAddressesList = createAction('ACTION/RESET_INVOICE_ADDRESSES_LIST');

export const addNewVenueRequest = createAction<AddNewVenueRequestPayload>('ACTION/ADD_NEW_VENUE_REQUEST');
export const addNewVenueSuccess = createAction('ACTION/ADD_NEW_VENUE_SUCCESS');
export const addNewVenueFailed = createAction('ACTION/ADD_NEW_VENUE_FAILED');
export const resetAddNewVenueModalStatus = createAction('ACTION/RESET_ADD_NEW_VENUE_MODAL_STATUS');

export const getVenueDetailsInvoiceAddressesRequest = createAction(
  'ACTION/GET_VENUE_DETAILS_INVOICE_ADDRESSES_REQUEST',
);
export const getVenueDetailsInvoiceAddressesSuccess = createAction<GetVenueDetailsInvoiceAddressesSuccessPayload>(
  'ACTION/GET_VENUE_DETAILS_INVOICE_ADDRESSES_SUCCESS',
);
export const getVenueDetailsInvoiceAddressesFailed = createAction('ACTION/GET_VENUE_DETAILS_INVOICE_ADDRESSES_FAILED');

export const putVenueDetailsRequest = createAction<PutVenueRequestPayload>('ACTION/PUT_VENUE_DETAILS');
export const putVenueDetailsSuccess = createAction<PutVenueSuccessPayload>('ACTION/PUT_VENUE_DETAILS_SUCCESS');
export const putVenueDetailsFailed = createAction<PutVenueFailedPayload>('ACTION/PUT_VENUE_DETAILS_FAILED');
export const showReturnToVenueModal = createAction<ShowReturnToVenueModalPayload>('ACTION/SHOW_RETURN_TO_VENUE_MODAL');

export const getVenueAffiliationsRequest = createAction<GetVenueAffiliationsRequestPayload>(
  'ACTION/GET_VENUE_AFFILIATIONS_REQUEST',
);
export const getVenueAffiliationsSuccess = createAction<GetVenueAffiliationsSuccessPayload>(
  'ACTION/GET_VENUE_AFFILIATIONS_SUCCESS',
);
export const getVenueAffiliationsFailed = createAction('ACTION/GET_VENUE_AFFILIATIONS_FAILED');
export const resetVenueAffiliations = createAction('ACTION/RESET_VENUE_AFFILIATIONS');

export const saveVenueStepsData = createAction('ACTION/SAVE_STEPS_DATA');
export const resetVenueStepsData = createAction('ACTION/RESET_STEPS_DATA');

export const getVenueTypesRequest = createAction<GetVenueTypesRequestPayload>('ACTION/GET_VENUE_TYPES_REQUEST');
export const getVenueTypesSuccess = createAction<GetVenueTypesSuccessPayload>('ACTION/GET_VENUE_TYPES_SUCCESS');
export const getVenueTypesFailed = createAction('ACTION/GET_VENUE_TYPES_FAILED');
export const resetVenueTypes = createAction('ACTION/RESET_VENUE_TYPES');

export const getVenueAdditionalServicesRequest = createAction<GetVenueAdditionalServicesRequestPayload>(
  'ACTION/GET_VENUE_ADDITIONAL_SERVICES_REQUEST',
);
export const getVenueAdditionalServicesSuccess = createAction<GetVenueAdditionalServicesSuccessPayload>(
  'ACTION/GET_VENUE_ADDITIONAL_SERVICES_SUCCESS',
);
export const getVenueAdditionalServicesFailed = createAction('ACTION/GET_VENUE_ADDITIONAL_SERVICES_FAILED');
export const resetVenueAdditionalServices = createAction('ACTION/RESET_VENUE_ADDITIONAL_SERVICES');

export const getCurrentVenuePraticeDetailsRequest = createAction('ACTION/GET_VENUE_CURRENT_PRACTICE_REQUEST');
export const getCurrentVenuePraticeDetailsSuccess = createAction<GetVenueCurrentPracticeStatusSuccessPayload>(
  'ACTION/GET_VENUE_CURRENT_PRACTICE_SUCCESS',
);
export const getCurrentVenuePraticeDetailsFailed = createAction('ACTION/GET_VENUE_CURRENT_PRACTICE_FAILED');
export const resetCurrentVenuePraticeDetails = createAction('ACTION/RESET_VENUE_CURRENT_PRACTICE');

export const getAllVenuePartnersRequest = createAction<any>('ACTION/GET_VENUE_PARTNER_REQUEST');
export const getAllVenuePartnersSuccess = createAction<any>('ACTION/GET_VENUE_PARTNER_SUCCESS');
export const getAllVenuePartnersFailed = createAction('ACTION/GET_VENUE_PARTNER_FAILED');
