import { CSSReset, useToast, Flex, Box } from '@chakra-ui/react';
import {
  Impersonated,
  clearAuthTokens,
  getAccessToken,
  refreshAuthTokens,
  storeAuthTokens,
} from '@common/auth/utils/token';
import FontFaceObserver from 'fontfaceobserver';
import countries from 'i18n-iso-countries';
import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { lazy } from 'react';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import useGoogleAnalytics from './hooks/analytics/useGoogleAnalytics';
import useAttachScriptToHTML from './hooks/useAttachScriptToHTML';
import { useInterval } from './hooks/useInterval';
import { messageHandlerReset } from './redux/messageHandler/actions';
import { messageHandlerFullInfo } from './redux/messageHandler/selectors';
import { AppRoutes } from './routes/routesList';

const PUHeader = lazy(() => import('./components/PUHeader'));
const ScrollToTop = lazy(() => import('./components/ScrollToTopOnMount'));
const AccessesDetails = lazy(() => import('./pages/Accesses/AccessesDetails'));
const CreateAccessPage = lazy(() => import('./pages/Accesses/CreateAccesses'));
const EditAccessesPage = lazy(() => import('./pages/Accesses/EditAccesses'));
const AgendaGoodmePage = lazy(() => import('./pages/AgendaGoodmePage'));
const AddDoctorPage = lazy(() => import('./pages/Doctors/AddDoctor'));
const DoctorDetailsPage = lazy(() => import('./pages/Doctors/DoctorDetailPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const Homepage = lazy(() => import('./pages/Homepage'));
const InvoicesPage = lazy(() => import('./pages/Invoices'));
const ManageAccesses = lazy(() => import('./pages/ManageAccesses'));
const PricelistsPage = lazy(() => import('./pages/ManagePricelists'));
const ManageStructuresPage = lazy(() => import('./pages/ManageStructures'));
const ManageVenues = lazy(() => import('./pages/ManageVenues'));
const NotificationsGoodmePage = lazy(() => import('./pages/NotificationsGoodmePage'));
const PatientsListGoodmePage = lazy(() => import('./pages/PatientsListGoodmePage'));
const AddPracticePage = lazy(() => import('./pages/Practices/CreatePractice'));
const EditPractice = lazy(() => import('./pages/Practices/EditPractice'));
const PracticesPage = lazy(() => import('./pages/Practices/ListPage'));
const PracticeDetailsPage = lazy(() => import('./pages/Practices/PracticeDetails'));
const AddPricelistPage = lazy(() => import('./pages/Pricelists/AddPricelist'));
const EditApprovedPricelist = lazy(() => import('./pages/Pricelists/EditApprovedPricelist'));
const EditPricelist = lazy(() => import('./pages/Pricelists/EditPricelist'));
const PricelistDetails = lazy(() => import('./pages/Pricelists/PricelistDetails'));
const PricelistSandboxDetails = lazy(() => import('./pages/Pricelists/PricelistSandboxDetails'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const SupportPage = lazy(() => import('./pages/Support/ListPage'));
const TicketDetailsPage = lazy(() => import('./pages/Support/TicketDetails'));
const AddVenuePage = lazy(() => import('./pages/Venues/AddVenue'));
const EditVenue = lazy(() => import('./pages/Venues/EditVenue'));
const VenueDetails = lazy(() => import('./pages/Venues/VenueDetails'));

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const App: FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const hasGeneralMessage = useSelector(messageHandlerFullInfo);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isManageOpen, setIsManageOpen] = useState(false);

  useGoogleAnalytics();

  // Remember to change the name of the font based on what you are using
  const font = new FontFaceObserver('Nunito Sans');
  const html = document.documentElement;

  const retrieveToken = async () => {
    const existingUserToken = await getAccessToken();

    if (!!existingUserToken) {
      const decodedToken: {
        exp: number;
      } = jwtDecode(existingUserToken);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken?.exp * 1000 < currentDate.getTime()) {
        refreshAuthTokens();
      }
    }
  };

  const isImpersonated = useMemo(() => {
    const impersonated = localStorage.getItem('is_impersonated')
      ? Boolean(localStorage.getItem('is_impersonated'))
      : false;
    return impersonated;
  }, []);

  useLayoutEffect(() => {
    html.classList.add('fonts-loading');

    font
      .load()
      .then(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-loaded');
      })
      .catch(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-failed');
      });
  });

  // useEffect(() => {
  //   if (isImpersonated) {
  //     window.addEventListener('beforeunload', alertUser);
  //     window.addEventListener('unload', handleTabClosing);
  //     return () => {
  //       window.removeEventListener('beforeunload', alertUser);
  //       window.removeEventListener('unload', handleTabClosing);
  //     };
  //   }
  // }, []);

  // const handleTabClosing = () => {
  //   localStorage.clear();
  // };

  // const alertUser = (event: any) => {
  //   console.log(event);
  //   event.preventDefault();
  //   event.returnValue = '';
  // };

  useEffect(() => {
    const IMPERSONATE_AUTH_TOKEN = 'impersonate_pu_token';
    const IMPERSONATE_REFRESH_TOKEN = 'impersonate_pu_refresh_token';
    const impersonateToken = Cookies.get(IMPERSONATE_AUTH_TOKEN);
    const impersonateTokenRefresh = Cookies.get(IMPERSONATE_REFRESH_TOKEN);
    if (impersonateToken && impersonateTokenRefresh) {
      clearAuthTokens(false);
      const decodedImpersonateToken: Impersonated = jwtDecode(impersonateToken);
      storeAuthTokens(impersonateToken, impersonateTokenRefresh, true);
      localStorage.setItem('is_impersonated', 'true');
      localStorage.setItem('impersonated_by', JSON.stringify(decodedImpersonateToken.impersonatedBy));
    }
  }, []);

  useEffect(() => {
    if (hasGeneralMessage?.message) {
      toast({
        description: hasGeneralMessage.message,
        status: hasGeneralMessage.type,
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      setTimeout(() => {
        dispatch(messageHandlerReset());
      }, 3000);
    }
  }, [hasGeneralMessage, toast, dispatch]);

  useInterval(async () => {
    await retrieveToken();
  }, 60000);

  useAttachScriptToHTML(
    `https://maps.googleapis.com/maps/api/js?libraries=places&key=${window.MyCareEnvironment.REACT_APP_GMAPS_API}&language=it&v=3.53`,
  );

  return (
    <Flex height="100%" width="100%">
      <CSSReset />
      <>
        <PUHeader
          isProfileOpen={isProfileOpen}
          setIsProfileOpen={setIsProfileOpen}
          isManageOpen={isManageOpen}
          setIsManageOpen={setIsManageOpen}
          isImpersonated={isImpersonated}
        />
        <ScrollToTop />

        {(isProfileOpen || isManageOpen) && (
          <Box
            position="fixed"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            width="100%"
            height="100%"
            display="block"
            zIndex={101}
          />
        )}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path={AppRoutes.Home} component={Homepage} />
          <Route exact path={AppRoutes.Practices} component={PracticesPage} />
          <Route exact path={AppRoutes.GoodmeAgenda} component={AgendaGoodmePage} />
          <Route exact path={AppRoutes.Profile} component={ProfilePage} />
          <Route exact path={AppRoutes.GoodmePatients} component={PatientsListGoodmePage} />
          <Route exact path={AppRoutes.GoodmeNotifications} component={NotificationsGoodmePage} />
          <Route exact path={AppRoutes.ManageVenues} component={ManageVenues} />
          <Route exact path={`${AppRoutes.VenueDetails}/:locationId/details`} component={VenueDetails} />
          <Route exact path={AppRoutes.AddVenue} component={AddVenuePage} />
          <Route exact path={`${AppRoutes.EditVenue}/:locationId/edit`} component={EditVenue} />
          <Route exact path={AppRoutes.ManagePricelists} component={PricelistsPage} />
          <Route exact path={AppRoutes.Invoices} component={InvoicesPage} />
          <Route exact path={AppRoutes.ManageStructures} component={ManageStructuresPage} />
          <Route
            exact
            path={`${AppRoutes.PricelistDetails}/:pricelistId/:serviceType/details`}
            component={PricelistDetails}
          />
          <Route
            exact
            path={`${AppRoutes.PricelistSandboxDetails}/:pricelistId/:serviceType/details`}
            component={PricelistSandboxDetails}
          />
          <Route exact path={AppRoutes.AddPricelistPage} component={AddPricelistPage} />
          <Route
            exact
            path={`${AppRoutes.EditPricelistPage}/:pricelistId/:serviceType/edit`}
            component={EditPricelist}
          />
          <Route
            exact
            path={`${AppRoutes.EditApprovedPricelist}/:pricelistId/:serviceType/:locationUUID/edit`}
            component={EditApprovedPricelist}
          />
          <Route exact path={`${AppRoutes.DoctorDetails}/:doctorId/details`} component={DoctorDetailsPage} />
          <Route exact path={`${AppRoutes.PracticeDetails}/:practiceId/details`} component={PracticeDetailsPage} />
          <Route exact path={`${AppRoutes.AddPractice}/:pType`} component={AddPracticePage} />
          <Route exact path={`${AppRoutes.EditPractice}/:practiceId/edit`} component={EditPractice} />
          <Route exact path={AppRoutes.Invoices} component={InvoicesPage} />
          <Route exact path={`${AppRoutes.InvoicesDetails}/:practiceId/details`} component={PracticeDetailsPage} />
          <Route exact path={`${AppRoutes.DoctorDetailsVenue}/:doctorId/details`} component={DoctorDetailsPage} />
          <Route exact path={`${AppRoutes.DoctorDetailsPricelist}/:doctorId/details`} component={DoctorDetailsPage} />
          <Route exact path={AppRoutes.ManageAccesses} component={ManageAccesses} />
          <Route exact path={`${AppRoutes.AccessesDetails}/:operatorId/details`} component={AccessesDetails} />
          <Route exact path={`${AppRoutes.EditAccessesDetails}/:operatorId/edit`} component={EditAccessesPage} />
          <Route exact path={AppRoutes.CreateAccessPage} component={CreateAccessPage} />
          <Route exact path={AppRoutes.Support} component={SupportPage} />
          <Route exact path={`${AppRoutes.TicketDetails}/:ticketId/details`} component={TicketDetailsPage} />
          <Route exact path={AppRoutes.AddDoctorPage} component={AddDoctorPage} />

          <Route exact path={AppRoutes.ErrorPage} component={ErrorPage} />

          <Route
            exact
            path={AppRoutes.GenericErrorPage}
            render={() => {
              window.location.href = process.env.PUBLIC_URL + '/generic-error.html';
            }}
          />

          <Route exact path="/">
            <Redirect to={AppRoutes.Home} />
          </Route>
        </Switch>
      </>
    </Flex>
  );
};

export default App;
